import { z } from 'zod'

const SYSTEM_ROLES = {
	Admin: 'Admin',
	SystemManager: 'System Manager',
	Analyst: 'Analyst',
	ReadOnly: 'Read Only',
} as const

export const ROLE_HIERARCHY = {
	'Admin': 4,
	'System Manager': 3,
	'Analyst': 2,
	'Read Only': 1,
} as const

export const Roles = z.nativeEnum(SYSTEM_ROLES)

export type Roles = z.infer<typeof Roles>

export type Theme = 'dark' | 'light' | 'system'

export type Notification = {
	id: string
	title: string
	type: 'user' | 'automation' | 'integration' | 'global'
	data: UserNotification | AutomationNotification | IntegrationNotification
	createdAt: Date
}

export type UserNotification = {
	user_name: string
	user_email: string
	role: 'analyst' | 'admin' | 'super_admin'
	created_by: string
}

export type AutomationNotification = {
	automation_id: string
	automation_name: string
	automation_status: 'started' | 'completed' | 'failed'
	started_by: string
}

export type IntegrationNotification = {
	integration_name: string
	integration_id: string
	integration_description: string
	integration_status: 'expired' | 'failed' | 'success'
}
