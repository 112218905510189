import {
	createRootRouteWithContext,
	Link,
	Outlet,
	redirect,
} from '@tanstack/react-router'

import { isUserAuthenticated } from '@/features/auth/hooks/useCognitoAuth'

import { LogoText } from '@/components/environment/logo-text'
import { Button } from '@/components/ui/button'

import type { QueryClient } from '@tanstack/react-query'

/**
 * Root layout that wraps the entire application.
 */
export const Route = createRootRouteWithContext<{ queryClient: QueryClient }>()(
	{
		beforeLoad: async ({ context: { queryClient }, location }) => {
			const isRoot = location.pathname === '/'
			if (!isRoot) return

			const isAuthenticated = await isUserAuthenticated(queryClient)

			// If user is not authenticated, redirect to the logout page to make sure the user session is cleared
			if (!isAuthenticated) {
				throw redirect({
					to: '/logout',
				})
			} else {
				throw redirect({
					to: '/graylog',
				})
			}
		},

		component: RootLayout,
		notFoundComponent: RootNotFound,
	},
)

function RootLayout() {
	return (
		<div className="flex h-screen w-full flex-row items-start justify-start overflow-hidden">
			<Outlet />
		</div>
	)
}

function RootNotFound() {
	return (
		<main className="flex h-screen w-full flex-col items-center justify-center">
			<LogoText className="my-8 w-48" />
			<p className="text-lg font-medium">
				We couldn't find the page you were looking for.
			</p>
			<Button variant="outline" asChild className="my-4">
				<Link to="/graylog">Go to home</Link>
			</Button>
		</main>
	)
}
