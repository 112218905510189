import { createContext, useContext, useEffect, useState } from 'react'

import { create, useStore } from 'zustand'
import { devtools, persist } from 'zustand/middleware'
import { createStore } from 'zustand/vanilla'

import type { Roles } from '../app/types'
import type { PropsWithChildren } from 'react'

// Make key a const so we can import it in other files
export const USER_STORE_KEY = '@bitlyft/user'

type UserState = {
	currentTenant: string | null
	currentRole: Roles | null
}

type UserActions = {
	setCurrentTenant: (tenantID: string | undefined) => void
	setCurrentRole: (role: Roles | null) => void
	reset: () => void
}

const initialState: UserState = {
	currentTenant: null,
	currentRole: null,
}

type UserStore = UserState & {
	actions: UserActions
}

/**
 * Create Zustand store for user state to be used outside of React components
 * ! This is a hacky read-only store that is not tied to the React component lifecycle,
 * ! but it's useful for getting the current tenant and role outside of React components (loaders)
 */
const userStore = create<UserState>()(() => ({
	...initialState,
}))

/**
 * Create Zustand store for user state
 */
const createUserStore = (init: UserState) => {
	return createStore<UserStore>()(
		devtools(
			persist(
				(set) => ({
					// Set initial state
					...init,

					actions: {
						setCurrentTenant: (tenantID) => {
							userStore.setState({ currentTenant: tenantID })
							return set(
								{ currentTenant: tenantID },
								false,
								'auth/setCurrentTenant',
							)
						},

						setCurrentRole: (role) => {
							userStore.setState({ currentRole: role })
							return set({ currentRole: role }, false, 'auth/setCurrentRole')
						},

						// Reset state to initial state
						reset: () => {
							userStore.setState(() => ({ ...initialState }))
							return set(() => ({ ...initialState }), false, 'auth/reset')
						},
					},
				}),

				/**
				 * We pass two more arguments to set so we can have a better dev experience
				 * and inspect with Redux DevTools
				 * @see: https://github.com/pmndrs/zustand?tab=readme-ov-file#redux-devtools
				 */

				// Name of the store in localStorage
				{
					name: USER_STORE_KEY,
					// We don't want to persist the actions object
					partialize: ({ actions, ...state }) => state,
					// We make sure to sync the state from localStorage to the global userStore
					onRehydrateStorage: () => (state) => {
						if (state) {
							// Set the read-only userStore to the state from localStorage
							userStore.setState(state)
						}
					},
				},
			),

			// Name of the store in Redux DevTools
			{ name: USER_STORE_KEY },
		),
	)
}

/**
 * Create context for the user store
 */
const UserStoreContext = createContext<ReturnType<
	typeof createUserStore
> | null>(null)

type UserStoreProviderProps = PropsWithChildren<{
	initialState: UserState
}>
/**
 * Provide the user store to the app
 * We use ReactContext to init the store with default values
 */
export function UserStoreProvider({
	children,
	initialState,
}: UserStoreProviderProps) {
	const [store] = useState(() => createUserStore(initialState))

	/**
	 * When we first mount the component, we set the current tenant and role
	 * to the initial state if they are not already set
	 */
	useEffect(() => {
		const currentTenant = store.getState().currentTenant
		const currentRole = store.getState().currentRole

		if (!currentTenant) {
			store.getState().actions.setCurrentTenant(initialState.currentTenant!)
		}

		if (!currentRole) {
			store.getState().actions.setCurrentRole(initialState.currentRole!)
		}
	}, [store])

	return (
		<UserStoreContext.Provider value={store}>
			{children}
		</UserStoreContext.Provider>
	)
}

/**
 * Get a value from the user store
 * @param selector - A function that selects a value from the user store
 * @returns The value from the user store
 */
export function useUserStore<T>(selector: (state: UserStore) => T): T {
	const store = useContext(UserStoreContext)

	if (!store) {
		throw new Error('useAuthStore must be used within a UserStoreProvider')
	}

	return useStore(store, selector)
}

/**
 * Get the current tenant
 */
export const useCurrentTenant = () =>
	useUserStore((state) => state.currentTenant)

/**
 * Get the current role
 */
export const useCurrentRole = () => useUserStore((state) => state.currentRole)

/**
 * Get the user actions
 */
export const useUserActions = () => useUserStore((state) => state.actions)

/**
 * Get the current tenant from the user store outside of React components
 */
export const getCurrentTenant = () => userStore.getState().currentTenant

/**
 * Get the current role from the user store outside of React components
 */
export const getCurrentRole = () => userStore.getState().currentRole
