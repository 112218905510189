import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'
import { QueryClient } from '@tanstack/react-query'
import { removeOldestQuery } from '@tanstack/react-query-persist-client'

import {
	appVersion,
	FIVE_MINUTES,
	QUERY_KEYS,
	THIRTY_SECONDS,
} from '../config/constants'

import type { PersistQueryClientOptions } from '@tanstack/react-query-persist-client'

/**
 * React Query Default Options
 * This will set the default stale time for all queries to 5 minutes
 * This can be overridden on a per-query basis
 */
export const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			staleTime: FIVE_MINUTES,
			gcTime: FIVE_MINUTES,
			throwOnError: true,
		},
	},
})

/**
 * Persist Query Client
 * This will persist the query client to the browser's local storage, allowing
 * the user to continue from where they left off after a page reload
 *
 * @see https://tanstack.com/query/latest/docs/framework/react/plugins/persistQueryClient
 */
export const persister = createSyncStoragePersister({
	storage: window.localStorage,
	key: '@bitlyft/query-cache',
	retry: removeOldestQuery,
	throttleTime: THIRTY_SECONDS,
	serialize: (data) =>
		JSON.stringify({
			version: appVersion,
			data,
		}),
	deserialize: (cachedString) => {
		const cached = JSON.parse(cachedString)

		if (cached.version !== appVersion) {
			return undefined
		}

		return cached.data
	},
})

export const persistOptions: PersistQueryClientOptions = {
	persister,
	queryClient,
	dehydrateOptions: {
		// Queries that should not be persisted
		shouldDehydrateQuery: (query) => {
			return ![QUERY_KEYS.AUTH.COGNITO_AUTH].includes(
				query.queryKey[0] as string,
			)
		},
	},
}

export const clearQueryCache = async () => {
	// Cancel any pending queries
	queryClient.cancelQueries()

	// Remove the persisted client
	await persister.removeClient()

	// Clear the query cache
	queryClient.clear()

	// Remove all queries
	queryClient.removeQueries()
}
