import { queryOptions, useSuspenseQuery } from '@tanstack/react-query'

import { defaultQueryRetry } from '@/utils/query-retry'

import { FIVE_MINUTES, QUERY_KEYS, THIRTY_MINUTES } from '@/config/constants'
import { api } from '@/lib/api'

import type { UserRequest } from '../types/api'

/**
 * Fetch the current user's data and save it to the user store
 */
export const fetchMe = async () => {
	// We fetch the user data
	const { data } = await api.get<UserRequest>('/me')

	return data
}

/**
 * Query options for fetching the current user's data
 */
export const getMeOptions = queryOptions({
	queryKey: [QUERY_KEYS.USER.ME],
	queryFn: fetchMe,
	retry: defaultQueryRetry,
	// Data will live in memory for 30 minutes after being fetched
	gcTime: THIRTY_MINUTES,
	// Data will be considered stale after 5 minutes
	staleTime: FIVE_MINUTES,
})

/**
 * Custom hook to fetch the current user's data using ReactQuery
 */
export const useGetMe = () => {
	return useSuspenseQuery(getMeOptions)
}
